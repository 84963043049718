import React, { useEffect, useMemo, useState } from 'react';
import { DefaultFormikValues, ExternalSystemForm, VehicleActionsProps } from './VehicleActions.type';
import { VehicleActionsContent } from './VehicleActions.view';
import { FormikValues } from 'formik';
import { useVehicleActionsQueries } from './VehicleActionsQueries';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { VehicleActionAtom } from 'states/component/Vehicle';
import Vehicle from 'api/Vehicle';
import { useTranslation } from 'react-i18next';
import { Success } from '../../Popup/Popup';
import { CRUD } from 'variables';
import { useQuery } from '@tanstack/react-query';
import { VehicleModel, VehicleActionType } from 'models/Vehicle.type';
import { ShowVehicleModal } from 'states/global/Modal';
import { VehicleModelModel } from 'models/VehicleModel.type';
import { RefreshTable } from '../../Ui/Components/UiTable2/UiTable.atom';
import useConverter from '../../CustomHooks/Converter/Converter';
import { checkRole } from 'helpers/authentication';
import { UserInfo } from 'states/global/User';
import { ROLES } from 'variables';
import { Wrapper } from 'helpers/wrapper';

const vehicleAPI = new Vehicle();

const VEHICLE_TYPE = {
    type: 'ADT',
    id: 1,
    maxSpeed: 24.1,
    icon: 'mining/48x48/adt_Yellow.png',
    maxSpeedTemperatureL1: 0,
    maxSpeedTemperatureL2: 0,
    maxSpeedTemperatureL3: 0,
    temperatureBasedMaxSpeed: false
};

export const DEFAULT_VALUES: DefaultFormikValues = {
    name: '',
    odometer: 0.0,
    maxSpeed: 0.0,
    icon: 'mining/48x48/adt_Yellow.png',
    model: {
        id: 1,
        modelName: '6 wheel 2x4',
        vehicleManufacturerId: 1,
        vehicleTypeId: 1,
        vehicleConfigurationId: 1
    },
    hub: null,
    comment: '',
    manufacturer: {
        id: 1,
        manufacturer: '---'
    },
    vehicleType: VEHICLE_TYPE,
    maxSpeedTemperatureL1: 0,
    maxSpeedTemperatureL2: 0,
    maxSpeedTemperatureL3: 0,
    enableTemperatureCheckbox: false,
    inheritMaxSpeed: false,
    externalSystems: [],
    predictionTemperature: false,
    genericTemperatureAlert: true,
    predictionTemperatureCriticalThreshold: 0.8,
    predictionTemperatureThresholdLevel0: 0,
    predictionTemperatureThresholdLevel1: 0,
    predictionTemperatureThresholdLevel2: 0
};

const VehicleActions: React.FC<VehicleActionsProps> = (props): JSX.Element => {
    const { fromServerToUserUnit, convertType, fromUserToServerUnit } = useConverter();
    const [defaultFormikValues, setDefaultFormikValues] = useState<DefaultFormikValues>(DEFAULT_VALUES);
    const vehicleActionsQueries = useVehicleActionsQueries();
    const setVehicleModal = useSetRecoilState(ShowVehicleModal);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const vehicleActionAtom = useRecoilValue(VehicleActionAtom);
    const resetVehicleActionAtom = useResetRecoilState(VehicleActionAtom);
    const { t: translate } = useTranslation();
    const refreshTable = useSetRecoilState(RefreshTable(`${props.queryKey}-RefreshTableAtom`));
    const [isNameUnique, setIsNameUnique] = useState<boolean>(false);
    const userInfo = useRecoilValue(UserInfo);
    const isSuperAdmin = useMemo(() => checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN]), [userInfo]);
    const defaultVehicleType = useMemo(
        () => (vehicleActionsQueries?.vehicleTypes && vehicleActionsQueries?.vehicleTypes[0]) || VEHICLE_TYPE,
        [vehicleActionsQueries.vehicleTypes]
    );

    const vehicleData = useQuery<VehicleActionType>(
        ['GetVehicle', vehicleActionAtom.action],
        () => vehicleAPI.get({ extendUrl: `${vehicleActionAtom.vehicleId as number}` }),
        {
            refetchOnWindowFocus: false,
            refetchInterval: false,
            enabled: vehicleActionAtom.action !== CRUD.ADD && !!vehicleActionAtom.vehicleId
        }
    );

    useEffect(() => {
        if (
            vehicleData.data &&
            (vehicleActionAtom.action === CRUD.EDIT || vehicleActionAtom.action === CRUD.CLONE) &&
            vehicleActionsQueries.externalSystems
        ) {
            const vehicleValues = vehicleData.data.vehicle;

            setDefaultFormikValues({
                name: vehicleActionAtom.action === CRUD.EDIT ? vehicleValues.name : defaultFormikValues.name,
                comment: vehicleValues.comment,
                icon: `${vehicleValues.icon}`,
                hub: vehicleActionAtom.action === CRUD.EDIT ? vehicleValues.device : defaultFormikValues.hub,
                maxSpeed: +fromServerToUserUnit({
                    type: convertType.speed,
                    value: vehicleValues.maxSpeed ?? 0,
                    fixed: 1
                }),
                odometer: +fromServerToUserUnit({
                    type: convertType.distance,
                    value: +vehicleValues.startOdometer ?? 0,
                    fixed: 1
                }),
                model: {
                    id: vehicleValues.vehicleModelId,
                    modelName: vehicleValues.vehicleModelV2,
                    vehicleConfigurationId: vehicleValues.vehicleConfigurationV2?.id,
                    vehicleTypeId: vehicleValues.vehicleType?.id,
                    vehicleManufacturerId: getManufacturerId(vehicleValues.vehicleModelId)
                },
                vehicleType: vehicleValues.vehicleType,
                manufacturer: {
                    id: getManufacturerId(vehicleValues.vehicleModelId),
                    manufacturer: vehicleValues.vehicleManufacturerV2
                },
                inheritMaxSpeed: vehicleValues.inheritMaxSpeed,
                enableTemperatureCheckbox: vehicleValues.temperatureBasedMaxSpeed,
                maxSpeedTemperatureL1: +fromServerToUserUnit({
                    type: convertType.speed,
                    value: vehicleValues.maxSpeedTemperatureL1 ?? 0,
                    fixed: 1
                }),
                maxSpeedTemperatureL2: +fromServerToUserUnit({
                    type: convertType.speed,
                    value: vehicleValues.maxSpeedTemperatureL2 ?? 0,
                    fixed: 1
                }),
                maxSpeedTemperatureL3: +fromServerToUserUnit({
                    type: convertType.speed,
                    value: vehicleValues.maxSpeedTemperatureL3 ?? 0,
                    fixed: 1
                }),
                externalSystems: isSuperAdmin
                    ? vehicleActionsQueries.externalSystems.externalSystem.map((externalSystem) => {
                          return {
                              externalSystemId: externalSystem.externalSystem.id,
                              externalSystemName: externalSystem.externalSystem.name,
                              value:
                                  vehicleActionAtom.action === CRUD.CLONE
                                      ? ''
                                      : vehicleValues.externalSystemVehicle.find(
                                            (vehicleExternal) =>
                                                vehicleExternal.externalSystemId === externalSystem.externalSystem.id
                                        )?.value || ''
                          };
                      })
                    : [],
                outOfService: vehicleValues.outOfService,
                predictionTemperature: vehicleValues?.predictionTemperature,
                genericTemperatureAlert: vehicleValues?.genericTemperatureAlert,
                predictionTemperatureCriticalThreshold: vehicleValues?.predictionTemperatureCriticalThreshold,
                predictionTemperatureThresholdLevel0: vehicleValues?.predictionTemperatureThresholdLevel0,
                predictionTemperatureThresholdLevel1: vehicleValues?.predictionTemperatureThresholdLevel1,
                predictionTemperatureThresholdLevel2: vehicleValues?.predictionTemperatureThresholdLevel2
            });
        } else {
            if (vehicleActionsQueries.externalSystems) {
                setDefaultFormikValues({
                    ...defaultFormikValues,
                    vehicleType:
                        (vehicleActionsQueries?.vehicleTypes && vehicleActionsQueries?.vehicleTypes[0]) || VEHICLE_TYPE,
                    externalSystems: isSuperAdmin
                        ? vehicleActionsQueries.externalSystems?.externalSystem.map((externalSystem) => {
                              return {
                                  externalSystemId: externalSystem.externalSystem.id,
                                  externalSystemName: externalSystem.externalSystem.name,
                                  value: ''
                              };
                          })
                        : []
                });
            }
        }
    }, [vehicleData.data, vehicleActionsQueries.externalSystems, vehicleActionsQueries.vehicleTypes]);

    const getExternalSystems = (
        exSystem: ExternalSystemForm[]
    ): { id?: number; externalSystemId?: number; value: string }[] => {
        return exSystem.map((system) => {
            if (vehicleActionAtom.action === CRUD.EDIT) {
                const systemOnVehicle = vehicleData?.data?.vehicle.externalSystemVehicle.find(
                    (vehicleExSystem) => vehicleExSystem.externalSystemId === system.externalSystemId
                );

                if (systemOnVehicle) {
                    return {
                        id: systemOnVehicle.id,
                        value: system.value
                    };
                }
            }

            return {
                externalSystemId: system.externalSystemId,
                value: system.value
            };
        });
    };

    const sendData = async (values: FormikValues): Promise<void> => {
        const isUnique = await vehicleAPI.getUniqVehicleName(values.name);

        if (isUnique && vehicleActionAtom.action !== 'edit') {
            setIsNameUnique(!!isUnique);
            return;
        }

        let data: Partial<VehicleModel> = {
            name: values.name,
            startOdometer: fromUserToServerUnit({
                type: convertType.distance,
                value: values.odometer,
                fixed: 1
            }),
            comment: values.comment,
            icon: values.icon,
            maxSpeed: fromUserToServerUnit({
                type: convertType.speed,
                value: values.maxSpeed,
                fixed: 1
            }),
            device: values.hub?.id > 0 ? values.hub.id : null,
            vehicleType: values.vehicleType.id,
            vehicleModelId: values.model.id,
            vehicleManufacturerId: values.manufacturer.id,
            predictionTemperature: values.predictionTemperature,
            genericTemperatureAlert: values.genericTemperatureAlert,
            predictionTemperatureCriticalThreshold: values.predictionTemperatureCriticalThreshold,
            predictionTemperatureThresholdLevel0: values.predictionTemperatureThresholdLevel0,
            predictionTemperatureThresholdLevel1: values.predictionTemperatureThresholdLevel1,
            predictionTemperatureThresholdLevel2: values.predictionTemperatureThresholdLevel2
        };

        if (isSuperAdmin) {
            data['externalSystemVehicle'] = getExternalSystems(values.externalSystems);
        }

        try {
            setIsProcessing(true);
            if (vehicleActionAtom.action === 'edit') {
                data.outOfService = values.outOfService;
                data.temperatureBasedMaxSpeed = values.enableTemperatureCheckbox;
                data.inheritMaxSpeed = values.inheritMaxSpeed;
                if (!values.inheritMaxSpeed && values.enableTemperatureCheckbox) {
                    data.maxSpeedTemperatureL1 = fromUserToServerUnit({
                        type: convertType.speed,
                        value: values.maxSpeedTemperatureL1 ?? 0,
                        fixed: 1
                    });
                    data.maxSpeedTemperatureL2 = fromUserToServerUnit({
                        type: convertType.speed,
                        value: values.maxSpeedTemperatureL2 ?? 0,
                        fixed: 1
                    });
                    data.maxSpeedTemperatureL3 = fromUserToServerUnit({
                        type: convertType.speed,
                        value: values.maxSpeedTemperatureL3 ?? 0,
                        fixed: 1
                    });
                }
                await vehicleAPI.patchById({ id: vehicleActionAtom.vehicleId as number, data });
                Success({ text: `${translate('t.vehicle')} ${translate('p.has_been_edited')}` });
            } else {
                await vehicleAPI.post({ data });
                Success({ text: `${translate('t.vehicle')} ${translate('p.has_been_added')}` });
            }
            props.onSuccess && props.onSuccess();
        } catch (e) {
            false;
        }
        setIsProcessing(false);
        resetVehicleActionAtom();
        setVehicleModal(false);
        setFormikValuesToDefault();
        refreshTable(true);
        await vehicleActionsQueries.availableDevices.refetch();
        setIsNameUnique(false);

        !checkRole(userInfo.user, [ROLES.ROLE_TECHNICAL_USER]) &&
            (await vehicleActionsQueries.availableDevices.refetch());
    };

    const getManufacturerId = (id: number): number => {
        let manufacturerId = 1;
        vehicleActionsQueries.vehicleModelData?.codeBook.forEach((val) => {
            if (val.id === id) {
                manufacturerId = val.vehicleManufacturerId;
            }
        });
        return manufacturerId;
    };

    const removeVehicle = async (): Promise<void> => {
        setIsProcessing(true);
        try {
            await vehicleAPI.delete({ id: vehicleActionAtom.vehicleId as number });
            Success({ text: `${translate('t.vehicle')} ${translate('p.has_been_deleted')}` });
            props.onSuccess && props.onSuccess();
        } catch (e) {
            false;
        }
        resetVehicleActionAtom();
        setVehicleModal(false);
        refreshTable(true);
        setIsProcessing(false);
    };

    const getVehicleConfiguration = (id: number): string => {
        let configuration = '';
        vehicleActionsQueries.vehicleConfiguration?.codeBook.forEach((val) => {
            if (val.id === id) {
                configuration = val.distribution;
            }
        });
        return configuration;
    };

    const getManufacturerOptions = (): number[] => {
        const compatibleModels = vehicleActionsQueries.vehicleModelData?.codeBook.reduce(
            (accumulator: Set<number>, model: VehicleModelModel) => {
                if (model.vehicleConfigurationId === defaultFormikValues.model.vehicleConfigurationId) {
                    accumulator.add(model.vehicleManufacturerId);
                }
                return accumulator;
            },
            new Set()
        );

        return Array.from(compatibleModels ?? new Set());
    };
    const checkUniquenessOfVehicleName = async (name: string): Promise<void> => {
        if (vehicleActionAtom.action !== CRUD.EDIT) {
            const isUnique = await vehicleAPI.getUniqVehicleName(name);
            setIsNameUnique(!!isUnique);
        }
    };

    const hasOnlySpaces = (str): boolean => {
        return /^\s*$/.test(str);
    };

    const setFormikValuesToDefault = (): void => {
        setDefaultFormikValues({
            ...DEFAULT_VALUES,
            vehicleType: defaultVehicleType
        });
    };

    return (
        <VehicleActionsContent
            data-testid={'VehicleActions-testid'}
            vehicleModelData={vehicleActionsQueries.vehicleModelData}
            availableDevices={
                !checkRole(userInfo.user, [ROLES.ROLE_TECHNICAL_USER])
                    ? vehicleActionsQueries.availableDevices.data
                    : null
            }
            isLoading={vehicleActionsQueries.isLoading || vehicleData.isFetching}
            removeVehicle={removeVehicle}
            isProcessing={isProcessing}
            onSubmit={sendData}
            getVehicleConfiguration={getVehicleConfiguration}
            defaultFormikValues={defaultFormikValues}
            vehicleManufacturerData={vehicleActionsQueries.vehicleManufacturerData}
            vehicleTypes={vehicleActionsQueries.vehicleTypes}
            getManufacturerOptions={getManufacturerOptions}
            checkUniquenessOfVehicleName={checkUniquenessOfVehicleName}
            isNameUnique={isNameUnique}
            setIsNameUnique={setIsNameUnique}
            hasOnlySpaces={hasOnlySpaces}
            setFormikValuesToDefault={setFormikValuesToDefault}
        />
    );
};

export default Wrapper(VehicleActions);
