import React from 'react';
import { DeviceInformationTableContainer, InfoContent, ListItemCustom } from './DeviceInformationTable.style';
import {
    DeviceInformationList,
    DeviceInformationListContent,
    DeviceInformationTableViewProps
} from './DeviceInformationTable.type';
import { Box, Collapse, Grid, ListItemText, Typography } from '@mui/material';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { snakeToCamel } from 'helpers/converter/text';

export const DeviceInformationTableContent: React.FC<DeviceInformationTableViewProps> = ({
    getDeviceInfo
}): JSX.Element => {
    const isMultiploThree = (numero: number): JSX.Element | JSX.Element[] => {
        const rest: number = numero % 3;
        const isMultiplo: boolean = rest === 0;
        const missing: number = isMultiplo ? 0 : 3 - rest;
        const missingNumbers: number[] = [];

        if (!isMultiplo) {
            for (let i = 1; i <= missing; i++) {
                missingNumbers.push(i);
            }

            return missingNumbers.length ? (
                missingNumbers.map((_number, i) => (
                    <Grid
                        item
                        lg={4}
                        md={4}
                        sm={4}
                        xs={2}
                        key={`deviceInfo-content-missing-${i}`}
                        sx={{ borderBottom: 'thin solid #cdcdcd' }}
                    ></Grid>
                ))
            ) : (
                <></>
            );
        }

        return <></>;
    };

    const generateList = (info: DeviceInformationList): JSX.Element => {
        const newList: DeviceInformationListContent[] = info.content ? info.content.filter((info) => !info.hidden) : [];

        return (
            <>
                <ListItemCustom
                    key={`deviceInfo-title-${info.title}`}
                    isActive={true}
                    data-testid={`deviceInfo-header-${1}`}
                >
                    <ListItemText>
                        <Box sx={{ display: 'inline-flex' }}>
                            <UiIcon icon={info.icon} size='lg' fixedWidth transform='left-6' />

                            <Typography variant='h5' gutterBottom>
                                <strong>{info.title}</strong>
                            </Typography>
                        </Box>
                    </ListItemText>
                </ListItemCustom>
                <Collapse in={true} timeout='auto' unmountOnExit sx={{ overflow: 'auto' }}>
                    <InfoContent>
                        <Grid container spacing={0.5}>
                            {newList.length ? (
                                newList.map((content, index) => {
                                    return (
                                        <Grid
                                            item
                                            lg={4}
                                            md={4}
                                            sm={4}
                                            xs={2}
                                            key={`deviceInfo-content-${index}`}
                                            sx={{ borderBottom: 'thin solid #cdcdcd' }}
                                        >
                                            <Box>
                                                <Typography variant='subtitle2' gutterBottom>
                                                    <strong>{content.label}:</strong>
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography
                                                    variant='caption'
                                                    gutterBottom
                                                    data-testid={`DeviceGeneralInfo-${snakeToCamel(
                                                        info.title.replaceAll(' ', '-')
                                                    )}-${snakeToCamel(content.label.replaceAll(' ', '-'))}-${index}`}
                                                >
                                                    {content.value}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    );
                                })
                            ) : (
                                <></>
                            )}
                            {isMultiploThree(newList.length)}
                        </Grid>
                    </InfoContent>
                </Collapse>
            </>
        );
    };

    return (
        <DeviceInformationTableContainer data-testid='DeviceInformationTableContent'>
            {getDeviceInfo ? getDeviceInfo().map((info) => generateList(info)) : <></>}
        </DeviceInformationTableContainer>
    );
};
